import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

export function CastingHiddenRouts(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
  const router = inject(Router);

  const url = route.url[0].path;

  if (url === 'job-bind-list' || url === 'marketplace' || url === 'express-interest') {
    router.navigate(['dashboard'], { queryParamsHandling: 'merge' });
    return false;
  }

  return true;
}
